import React from "react";
import Layout from "components/layout";
import Register from "components/userAccount/register";
import Seo from "components/seo";

function index() {
  return (
    <Layout>
      <Seo title="Register - User" slug="/user/register" />
      <Register />
    </Layout>
  );
}

export default index;
