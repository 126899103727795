import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { usePostSignUp } from "hooks/Auth/useSignUpForm";
import Loader from "components/loader";
import { Link, navigate } from "gatsby";
import DataProvider from "Context/DataContext";
import formCommonPostData from "utils/form-common-post-data";
import {
  errorMessage,
  errorMessageEmail,
  patternEmail,
} from "utils/form-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

function Register() {
  const { LocalObject } = useContext(DataProvider);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(false);
  const [checkSignUp, setCheckSignUp] = useState("");
  const { status, mutateAsync } = usePostSignUp();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const checkLogin = LocalObject.localStorage.getItem("access_token");
    if (checkLogin) {
      return navigate("/user/dashboard");
    }
  }, []);

  const onSubmit = (data) => {
    if (data.password !== data.confirm_password) {
      return setError("Passwords do not match.");
    }

    const postData1 = {
      email: data.email,
      username: data.name,
      password: data.password,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((res) => {
      setCheckSignUp(res.message);
      if (res.message === "Resigered Successfully") {
        reset();
      }
    });
  };
  const styles = {
    background:
      "transparent linear-gradient(180deg, #F1F6F8 0%, #F9FAFB 61%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
  };
  return (
    <div className="bg-midBlue py-6 flex mx-auto justify-center">
      <form
        style={styles}
        className="relative  sm:mx-auto max-w-[482px] w-11/12 sm:w-full px-2 sm:px-10  py-8 "
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="text-center text-[35px] font-secondary font-semibold my-5">
          Sign Up
        </p>
        <h2 className="text-center text-base font-normal font-arial mb-8 ">
          Sign Up to new Tomedes account.
        </h2>
        <label className="mb-5 block">
          <input
            type="text"
            name="name"
            className="text-lg font-arial font-normal px-4 w-full h-[50px] border border-quotetext-100 rounded-lg outline-none"
            placeholder="User Name"
            {...register("name", { required: true })}
          />
          {errors.name && (
            <span className="text-xs font-opensans -mt-1 font-normal text-left w-full text-red">
              {errorMessage}
            </span>
          )}
          {checkSignUp === "Username Exists" ? (
            <span className="text-xs -mt-1 font-opensans font-normal text-left w-full text-red">
              This UserName is already exists
            </span>
          ) : null}
        </label>
        <label className="mb-5 block">
          <input
            type="email"
            name="email"
            className="text-lg font-arial font-normal px-4 w-full h-[50px] border border-quotetext-100 rounded-lg outline-none"
            placeholder="Email address"
            {...register("email", {
              required: true,
              pattern: patternEmail,
            })}
          />
          {errors.email && (
            <span className="text-xs font-opensans font-normal text-left w-full text-red -mt-1">
              {errorMessageEmail}
            </span>
          )}
          {checkSignUp === "Email Exists" ? (
            <span className="text-xs font-opensans font-normal text-left w-full text-red">
              This email is already exists
            </span>
          ) : null}
        </label>

        <div className="mb-5">
          <label className="w-full bg-white flex justify-between items-center text-lg font-arial font-normal px-4 h-[50px] border border-quotetext-100 rounded-lg">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              className="w-full bg-transparent outline-none"
              // className="text-lg font-arial font-normal px-4 w-full h-[50px] border border-quotetext-100 rounded-lg mb-1"
              placeholder="Password"
              {...register("password", {
                required: true,
                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/,
              })}
            />

            <span
              onClick={() => setShowPassword(!showPassword)}
              className="cursor-pointer"
            >
              {!showPassword ? (
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  className="text-quotetext-100  w-[10px] mr-2"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faEye}
                  className="text-quotetext-100  w-[10px] mr-2"
                />
              )}
            </span>
          </label>
          {errors.password && (
            <span className="text-red block text-xs mt-2 font-opensans">
              Password is required
            </span>
          )}
        </div>
        <div className="mb-5">
          <label className="w-full bg-white flex justify-between items-center text-lg font-arial font-normal px-4 h-[50px] border border-quotetext-100 rounded-lg">
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_password"
              className="w-full bg-transparent outline-none"
              // className="text-lg font-arial font-normal px-4 w-full h-[50px] border border-quotetext-100 rounded-lg mb-1"
              placeholder="Confirm Password"
              {...register("confirm_password", {
                required: true,
                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/,
              })}
            />

            <span
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="cursor-pointer"
            >
              {!showConfirmPassword ? (
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  className="text-quotetext-100  w-[10px] mr-2"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faEye}
                  className="text-quotetext-100  w-[10px] mr-2"
                />
              )}
            </span>
          </label>
          {errors.confirm_password && (
            <span className="text-red block text-xs mt-2 font-opensans">
              Confirm Password is required
            </span>
          )}
        </div>

        <div className="flex items-center justify-between">
          <label>
            <input type="checkbox" name="" className="mr-2" />
            <span className="text-base font-arial font-normal">
              Remember me
            </span>
          </label>

          <p className="text-base font-arial font-normal text-[#273F77]"></p>
        </div>

        {error && (
          <p className="text-red block text-xs mt-2 font-opensans">{error}</p>
        )}
        <input type="hidden" id="zc_gad" name="zc_gad" value="" />
        <button
          className="w-full h-[56px] bg-orange text-white font-arial text-xl my-8 rounded-lg font-bold"
          type="submit"
        >
          {status === "loading" ? <Loader /> : "Sign up"}
        </button>
        {checkSignUp === "Resigered Successfully" ? (
          <span className="text-green-500 text-center">
            <i>Thank you!</i>
          </span>
        ) : null}
        <p className="text-base font-opensans text-center">
          Already have an account? .{" "}
          <Link
            className="cursor-pointer text-orange underline"
            to="/user/login"
          >
            {" "}
            Sign in
          </Link>
          {"  "}
          now.
        </p>

        <p className="text-base font-opensans text-center">
          Your information will be kept confidential
        </p>
      </form>
    </div>
  );
}

export default Register;
